import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';

import { useTranslate } from '../translate';

function Footer() {

    const navigate = useNavigate();

    const i18n = useTranslate();
    const { t } = i18n;

    return (
        <footer>
            <Button onClick={() => navigate('about')} type='text'>
                {t('footer.about')}
            </Button>
            <Button onClick={() => navigate('contact')} type='text'>
                {t('footer.contact')}
            </Button>
            <Button onClick={() => navigate('newsletter')} type='text'>
                {t('footer.newsletter')}
            </Button>
        </footer>
    );
}

export default Footer;
