import { useEffect, useState } from 'react';
import { Table, Select, Space } from 'antd';
import Plotly from 'plotly.js-basic-dist';
import createPlotlyComponent from 'react-plotly.js/factory';

import { getStatistics } from '../../../rest/Querier';
import { useTranslate } from '../../../translate';

function Statistics() {

    const i18n = useTranslate();
    const { t } = i18n;

    const Plot = createPlotlyComponent(Plotly);

    const defaultDate = 'All';

    const [viewsPerDate, setViewsPerDate] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [viewMode, setViewMode] = useState('home');
    const [dates, setDates] = useState({});
    const [selectedDate, setSelectedDate] = useState(defaultDate);

    const [homePerDate, setHomePerDate] = useState({});
    const [introductionPerDate, setIntroductionPerDate] = useState({});
    const [aboutPerDate, setAboutPerDate] = useState({});

    const [homeTrendDates, setHomeTrendDates] = useState([]);
    const [introductionTrendDates, setIntroductionTrendDates] = useState([]);
    const [aboutTrendDates, setAboutTrendDates] = useState([]);

    const [homeData, setHomeData] = useState(0);
    const [introductionData, setIntroductionData] = useState(0);
    const [aboutData, setAboutData] = useState(0);

    const [homeTrendViews, setHomeTrendViews] = useState([]);
    const [introductionTrendViews, setIntroductionTrendViews] = useState([]);
    const [aboutTrendViews, setAboutTrendViews] = useState([]);

    const [chapterTrendData, setChapterTrendData] = useState([]);

    const columns = [
        {
            title: t('statistics.list.chapter'),
            dataIndex: 'chapter',
            key: 'chapter',
        },
        {
            title: t('statistics.list.views'),
            dataIndex: 'views',
            key: 'views',
          }
    ];

    useEffect(() => {
        getStatistics()
            .then((result) => {
                if (result.status === 'OK') {

                    // Home
                    const homeDatesButAll = [];
                    const homeViewsButAll = [];
                    const homeDates = [];
                    Object.entries(result.data['home'])
                        .forEach(([date, views]) => {
                            if (date === 'All') {
                                homeDates.unshift({ value: date, label: t('statistics.date.sum') });
                            } else {
                                homeDates.unshift({ value: date, label: date });
                                homeDatesButAll.unshift(date);
                                homeViewsButAll.unshift(views);
                            }
                    });
                    setHomePerDate(result.data['home']);
                    setHomeData(result.data['home'][defaultDate]);
                    setHomeTrendDates(homeDatesButAll);
                    setHomeTrendViews(homeViewsButAll);

                    // Introduction
                    const introductionDatesButAll = [];
                    const introductionViewsButAll = [];
                    const introductionDates = [];
                    Object.entries(result.data['introduction'])
                        .forEach(([date, views]) => {
                            if (date === 'All') {
                                introductionDates.unshift({ value: date, label: t('statistics.date.sum') });
                            } else {
                                introductionDates.unshift({ value: date, label: date });
                                introductionDatesButAll.unshift(date);
                                introductionViewsButAll.unshift(views);
                            }
                    });
                    setIntroductionPerDate(result.data['introduction']);
                    setIntroductionData(result.data['introduction'][defaultDate]);
                    setIntroductionTrendDates(introductionDatesButAll);
                    setIntroductionTrendViews(introductionViewsButAll);

                    // About
                    const aboutDatesButAll = [];
                    const aboutViewsButAll = [];
                    const aboutDates = [];
                    Object.entries(result.data['about'])
                        .forEach(([date, views]) => {
                            if (date === 'All') {
                                aboutDates.unshift({ value: date, label: t('statistics.date.sum') });
                            } else {
                                aboutDates.unshift({ value: date, label: date });
                                aboutDatesButAll.unshift(date);
                                aboutViewsButAll.unshift(views);
                            }
                    });
                    setAboutPerDate(result.data['about']);
                    setAboutData(result.data['about'][defaultDate]);
                    setAboutTrendDates(aboutDatesButAll);
                    setAboutTrendViews(aboutViewsButAll);

                    // Per date
                    const retrievedViewsPerDate = [];
                    const chapterDates = []
                    Object.entries(result.data['date']).forEach(([date, chapters]) => {
                        const viewsPerChapter = [];
                        Object.entries(chapters).forEach(([chapter, views]) => {
                            viewsPerChapter.push({
                                key: `${date}>${chapter}`,
                                chapter,
                                views
                            });
                        });
                        retrievedViewsPerDate[date] = viewsPerChapter;
                        if (date === 'All') {
                            chapterDates.unshift({ value: date, label: t('statistics.date.sum') });
                        } else {
                            chapterDates.unshift({ value: date, label: date });
                        }
                    });
                    setViewsPerDate(retrievedViewsPerDate);
                    setTableData(retrievedViewsPerDate[defaultDate]);

                    // Per chapter
                    const retrievedViewsPerChapter = [];
                    Object.keys(result.data['chapter']).forEach((chapter) => {
                        retrievedViewsPerChapter.push({
                            x: result.data['chapter'][chapter]['dates'],
                            y: result.data['chapter'][chapter]['views'],
                            type: 'scatter',
                            mode: 'lines+markers',
                            name: chapter
                        });
                    });
                    setChapterTrendData(retrievedViewsPerChapter);

                    // Dates per mode
                    setDates({
                        home: homeDates,
                        introduction: introductionDates,
                        about: aboutDates,
                        chapter: chapterDates
                    });
                }

            });
    }, [t]);

    const handleDateChange = (selectedDate: string) => {
        setSelectedDate(selectedDate);
        setTableData(viewsPerDate[selectedDate]);
        setHomeData(homePerDate[selectedDate]);
        setIntroductionData(introductionPerDate[selectedDate]);
        setAboutData(aboutPerDate[selectedDate]);
    };

    const handleModeChange = (selectedMode: string) => {
        setViewMode(selectedMode);
        if(selectedMode === 'trend') {
            handleDateChange('All');
        }
    }

    return (
        <div id='content'>
            <h1>{t('statistics')}</h1>
            <Space direction='vertical' size='middle'>
                <div>
                    <Select
                        defaultValue='home'
                        style={{ width: 145 }}
                        onChange={handleModeChange}
                        options={[
                            { value: 'home', label: t('statistics.view.home') }, 
                            { value: 'introduction', label: t('statistics.view.introduction')},
                            { value: 'about', label: t('statistics.view.about')},
                            { value: 'chapter', label: t('statistics.view.chapter') },
                            { value: 'trend', label: t('statistics.view.trend') },
                        ]}
                    />
                    <Select
                        defaultValue={defaultDate}
                        value={selectedDate}
                        style={{ width: 150, paddingLeft: 5 }}
                        onChange={handleDateChange}
                        options={dates[viewMode]}
                        disabled={viewMode === 'trend'}
                    />
                </div>
                <div style={{display: viewMode === 'chapter' ? 'block': 'none'}}>
                    <Table 
                        columns={columns} 
                        dataSource={tableData} 
                        size='middle'
                    />
                </div>
                <div style={{display: viewMode === 'home' ? 'block': 'none'}}>
                    {t('statistics.home.total')}: {homeData}
                    <span style={{display: selectedDate === 'All' ? 'block' : 'none'}}>
                        <Plot
                            data={[
                                {
                                    x: homeTrendDates,
                                    y: homeTrendViews,
                                    type: 'scatter',
                                    mode: 'lines+markers',
                                    marker: {color: 'grey'},
                                }
                            ]}
                            layout={{width: 320, height: 240}}
                            config={{displayModeBar: false}}
                        />
                    </span>
                </div>
                <div style={{display: viewMode === 'introduction' ? 'block': 'none'}}>
                    {t('statistics.introduction.total')}: {introductionData}
                    <span style={{display: selectedDate === 'All' ? 'block' : 'none'}}>
                        <Plot
                            data={[
                                {
                                    x: introductionTrendDates,
                                    y: introductionTrendViews,
                                    type: 'scatter',
                                    mode: 'lines+markers',
                                    marker: {color: 'grey'},
                                }
                            ]}
                            layout={{width: 320, height: 240}}
                            config={{displayModeBar: false}}
                        />
                    </span>
                </div>
                <div style={{display: viewMode === 'about' ? 'block': 'none'}}>
                    {t('statistics.about.total')}: {aboutData}
                    <span style={{display: selectedDate === 'All' ? 'block' : 'none'}}>
                        <Plot
                            data={[
                                {
                                    x: aboutTrendDates,
                                    y: aboutTrendViews,
                                    type: 'scatter',
                                    mode: 'lines+markers',
                                    marker: {color: 'grey'},
                                }
                            ]}
                            layout={{width: 320, height: 240}}
                            config={{displayModeBar: false}}
                        />
                    </span>
                </div>
                <div style={{display: viewMode === 'trend' ? 'block': 'none'}}>
                    <Plot
                        data={chapterTrendData}
                        layout={{
                            width: 320, 
                            height: 380, 
                            legend: {
                                orientation: 'h',
                                y: -0.5
                            }
                        }}
                        config={{displayModeBar: false}}
                    />
                </div>
            </Space>
        </div>
    );
}

export default Statistics;
