import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser';

import { useTranslate } from '../../../translate';
import { getAboutText } from '../../../rest/Querier';
import { useAppContext } from '../../hooks';


function About() {
  const i18n = useTranslate();
  const { t } = i18n;

  const { state: { mode } } = useAppContext();
  
  const [text, setText] = useState('');
  const [title, setTitle] = useState('');

  useEffect(() => {
      getAboutText(mode)
        .then((result) => {
          if(result.status === 'OK') {
            setTitle(result.data.title);
            setText(result.data.text);
          } else {
            setText(t('about.text.notFound'));
          }
        })
        .catch(() => setText(t('about.text.notFound')));
    }, [t, mode]);

  return (
    <div id='content'>
        <h1 id='mainTitle'>{title}</h1>
        <div id='text' className='text'>{parse(text)}</div>
    </div>
  );
}

export default About;
