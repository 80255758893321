import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button } from 'antd';

import { useTranslate } from '../../translate';
import { useAppContext } from '../hooks';
import { postView } from '../../rest/Querier';

function Home() {
    
  const i18n = useTranslate();
  const { t } = i18n;

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { state: { mode } } = useAppContext();

  useEffect(() => {
    if (mode !== 'administration' && searchParams.get('mode') !== 'administration') {
      postView('Accueil', 'home');
    }
  }, [mode, searchParams])

  return (
    <div id='content'>
        <h1>12^2</h1>
        <Button onClick={() => navigate('book/introduction')}>{t('home.book')}</Button>
        <p>{t('home.demo')}</p>
        <video controls style={{ width: '65%' }}>
          <source src='/static/demo.mov' />
        </video>
    </div>
  );
}

export default Home;
