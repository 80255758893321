import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, message } from 'antd';
import { HomeOutlined, ShareAltOutlined, BookOutlined, UserOutlined } from '@ant-design/icons';

import { useAppContext } from './hooks';
import { setMode } from '../state/actions';
import { t } from '../translate/translate';

function Header() {

  const [searchParams] = useSearchParams();
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  const { state: { mode }, dispatch } = useAppContext();

  useEffect(() => {
    const newMode = searchParams.get('mode');
    if (newMode && (!mode || mode !== newMode)) {
      dispatch(setMode(newMode));
    }
  }, [dispatch, mode, searchParams]);

  const share = () => {
    const link = window.location.href;
    try {
      navigator.clipboard.writeText(link);
      messageApi.open({
        type: 'success',
        content: t('header.share.success'),
        duration: 1,
        className: 'sharingMessage'
      });
    } catch (e) {
      messageApi.open({
        type: 'error',
        content: t('header.share.error'),
        duration: 1,
        className: 'sharingMessage'
      });
    }
  };

  return (
    <header>
      {contextHolder}
      <Button
        onClick={() => navigate('')}
        icon={<HomeOutlined />}
      />
      <Button
        onClick={() => navigate('book/introduction')}
        icon={<BookOutlined />}
      />
      <Button
        onClick={share}
        icon={<ShareAltOutlined />}
      />
      <Button
        onClick={() => navigate('login')}
        icon={<UserOutlined />}
        style={{display: mode === 'administration' ? 'inline-block' : 'none' }}
      />
    </header>
  );
}

export default Header;
