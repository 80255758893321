import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, AlertProps, Button, Form, Input, Radio, RadioChangeEvent, Space } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { useTranslate } from '../../../translate';
import { createChapter } from '../../../rest/Querier';

function ChapterCreation() {
    
    const i18n = useTranslate();
    const { t } = i18n;

    const navigate = useNavigate();

    const [form] = Form.useForm();

    const [result, setResult] = useState('');
    const [type, setType] = useState('chapter');

    const onCreateChapter = (values) => {
        const chapterPayload = {
            title: values.title,
            abstract: values.abstract,
            text: values.text,
            type: values.type,
            notes: values.notes
        }
        createChapter(chapterPayload)
            .then(result => {
                if(result.status === 'OK') {
                    setResult('chapter.create.success');
                    setTimeout(function() {
                       navigate('/administration')
                    }, 1000);
                } else {
                    setResult(result.message);
                }
            });
    }

    const showResult = () => {
        if(result) {
            return <Alert 
                message={t(`form.${result}`)} 
                type={result === 'chapter.create.success' ? 'success' : 'error' as AlertProps['type']} 
                showIcon 
            />;
        }
        return '';
      }

    const handleTypeChange = (e: RadioChangeEvent) => {
        const type = e.target.value;
        form.setFieldValue('type', type);
        setType(type);
        if(type === 'introduction') {
            form.setFieldValue('title', 'Introduction');
            form.setFieldValue('notes', undefined);
        } else if(type === 'about') {
            form.setFieldValue('title', 'À propos');
            form.setFieldValue('notes', undefined);
        } else {
            form.setFieldValue('title', '');
        }
    }

    return (
        <div id='content'>
            <h1>{t('form.chapter.create')}</h1>
            <Form 
                form={form}
                onFinish={onCreateChapter}
                labelCol={{ flex: '110px' }}
                labelAlign='left'
                labelWrap
                wrapperCol={{ flex: 1 }}
                className='chapterForm'>
                <Form.Item
                    name='type'
                    label={t('form.chapter.create.type')}
                    rules={[
                        {required: true, message: t('form.chapter.create.typeRequired')}
                    ]}
                    initialValue={'chapter'}>
                    <Radio.Group onChange={handleTypeChange}>
                        <Radio id='chapter' value='chapter'>{t('form.chapter.create.type.chapter')}</Radio>
                        <Radio id='introduction' value='introduction'>{t('form.chapter.create.type.introduction')}</Radio>
                        <Radio id='about' value='about'>{t('form.chapter.create.type.about')}</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    name='title'
                    label={t('form.chapter.create.title')}
                    rules={[
                        {required: true, message: t('form.chapter.create.titleRequired')}
                    ]}>
                    <Input maxLength={50} disabled={type === 'introduction' || type === 'about'} />
                </Form.Item>
                <Form.Item
                    name='abstract'
                    label={t('form.chapter.create.abstract')}
                    rules={[
                        {required: type === 'chapter', message: t('form.chapter.create.abstractRequired')}
                    ]}
                    style={{display: type === 'introduction' || type === 'about' ? 'none': ''}}>
                    <Input.TextArea maxLength={500} />
                </Form.Item>
                <Form.Item
                    name='text'
                    label={t('form.chapter.create.text')}
                    rules={[
                        {required: type === 'introduction' || type === 'about', message: t('form.chapter.create.textRequired')}
                    ]}>
                    <Input.TextArea maxLength={65000} />
                </Form.Item>
                <h2 style={{paddingBottom: 8, display: type === 'introduction' || type === 'about' ? 'none': ''}}>Notes</h2>
                <span style={{display: type === 'introduction' || type === 'about' ? 'none': ''}}>
                    <Form.List name='notes'>
                        {(fields, { add, remove }) => (
                            <span>
                            {fields.map(({ key, name, ...restField }) => (
                            <Space key={key} style={{ marginBottom: 8 }} align='baseline'>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'title']}
                                    label={t('form.chapter.create.note.title')}
                                    rules={[{ required: true, message: t('form.chapter.create.notes.add.titleRequired') }]}
                                    >
                                    <Input maxLength={50}/>
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'text']}
                                    label={t('form.chapter.create.note.text')}
                                    rules={[{ required: true, message: t('form.chapter.create.notes.add.textRequired') }]}
                                    >
                                    <Input.TextArea maxLength={500} />
                                </Form.Item>
                                <MinusCircleOutlined onClick={() => remove(name)} />
                            </Space>
                            ))}
                            <Form.Item>
                                <Button type='dashed' onClick={() => add()} style={{width: '50%'}} block icon={<PlusOutlined />}>
                                    {t('form.chapter.create.notes.add')}
                                </Button>
                            </Form.Item>
                        </span>
                        )}
                    </Form.List>
                </span>
                <Form.Item>
                    <Button type='primary' htmlType='submit' style={{width: '50%'}}>
                        {t('form.chapter.create.submit')}
                    </Button>
                </Form.Item>
            </Form>
            {showResult()}
        </div>);
}

export default ChapterCreation;
