import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';
import { Button } from 'antd';

import { useTranslate } from '../../../translate';
import { useAppContext } from '../../hooks';

function Chapter({chapter, addChapterTab}) {
  
  const navigate = useNavigate();

  const i18n = useTranslate();
  const { t } = i18n;

  const { state: { mode, chapters, chapterScroll } } = useAppContext();

  const [readMore, setReadMore] = useState(false);

  const options = {
      replace: node => {
        if (node.attribs && node.attribs.link) {
          Object.assign(
            node.attribs, 
            {onClick: () => {
              addChapterTab(node.attribs.link, chapter.id, chapters, chapterScroll, mode);
            }}
          );
          return node;
        }
      }
  };
    
  const parseText = () => {
      try {
        return (
          <div 
            id='text' 
            className='text'>
            {parse(chapter.text, options)}
          </div>
        );
      } catch (e) {
          return <div id='emptyText' />;
      }
  }

  const parseNotes = () => {
      try {
        return readMore && chapter.notes && chapter.notes.length > 0 ? (
            <div id='notes'>
              <h2>{t('chapter.notes')}</h2>
              {chapter.notes.map(note =>
                <div key={note.title}>
                  <h3>{note.title}</h3>
                  <p className='text'>{parse(note.text)}</p>
                </div>
              )}
            </div>  
          ) : <span/>;
      } catch (e) {
          return <span/>;
      }
  }

    return (
      <div id='content' style={{paddingTop: 40}}>
          <h1 id='mainTitle'>
            {chapter.title}
            <div className='version'>{chapter.version}</div>
          </h1>
          <div id='abstract' className='text' style={{fontWeight: 'bold'}}>
            {parse(chapter.abstract)}
          </div>
          {parseText()}
          <Button 
            onClick={() => setReadMore(true)} 
            style={{display: readMore || !(chapter.notes && chapter.notes.length > 0) ? 'none' : ''}}
          >
            {t('chapter.readmore')}...
          </Button>
          {parseNotes()}
          <Button 
            onClick={() => navigate('/book/toc')}
            style={{display: readMore || (chapter.notes && chapter.notes.length === 0) ? '': 'none'}}
          >
            {t('chapter.toc')}
          </Button>
      </div>
    );
}
  
export default Chapter;
  