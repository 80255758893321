import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';
import { Button } from 'antd';

import { useTranslate } from '../../../translate';
import { getIntroductionText } from '../../../rest/Querier';
import { useAppContext } from '../../hooks';

function Introduction() {

  const i18n = useTranslate();
  const { t } = i18n;

  const navigate = useNavigate();

  const { state: { mode } } = useAppContext();

  const [text, setText] = useState('');
  const [version, setVersion] = useState('');

  useEffect(() => {
    getIntroductionText(mode)
      .then((result) => {
        if(result.status === 'OK') {
          setText(result.data.text);
          setVersion(result.data.version)
        } else {
          setText(t('introduction.text.notFound'));
        }
      })
      .catch(() => setText(t('introduction.text.notFound')));
  }, [t, mode]);

  return (
    <div id='content'>
      <p className='version'>{version}</p>
      <div id='text' className='text'>{parse(text)}</div>
      <Button onClick={() => navigate('../book/toc')}>{t('introduction.toc')}</Button>
    </div>
  );
}

export default Introduction;
