import { useTranslate } from '../../../translate';

function Contact() {
    
  const i18n = useTranslate();
  const { t } = i18n;

  return (
    <div id='content'>
        <h1 id='mainTitle'>{t('contact.title')}</h1>
        <p>{t('contact.content')}: <a className='mailTo' href='mailto:contact.12aucarre@gmail.com'>contact.12aucarre@gmail.com</a>.</p>
    </div>
  );
}

export default Contact;
