import React, { useState } from 'react';

import { Form, Input, Button, Space, Alert, AlertProps } from 'antd';

import { useTranslate } from '../../../translate';
import { postSubscription } from '../../../rest/Querier';


function Newsletter() {
  const i18n = useTranslate();
  const { t } = i18n;

  const [form] = Form.useForm();

  const [subscribe, setSubscribe] = useState(true);
  const [result, setResult] = useState('');

  const onSubscibe = (values) => {
    postSubscription(values['email'], subscribe)
        .then(() => setResult('success'))
        .catch(() => setResult('error'));
  }

  const showResult = () => {
    if(result) {
        return <Alert 
            message={t(`newsletter.${subscribe ? 'subscription': 'unsubscription'}.${result}`)} 
            type={result as AlertProps['type']} 
            showIcon 
        />;
    }
    return '';
  }

  return (
    <div id='content'>
        <h1 id='mainTitle'>{t('newsletter.title')}</h1>
        <p>{t('newsletter.content')}</p>
        <Form
            form={form}
            onFinish={onSubscibe}
            className='responsiveNewsletterForm'>
            <Form.Item
                name='email'
                label={t('newsletter.email.label')}
                rules={[
                    {required: true, message:t('newsletter.email.input.error.emailRequired')}, 
                    {type: 'email', message: t('newsletter.email.input.error.emailFormat')}, 
                    {type: 'string', min: 6, message: t('newsletter.email.input.error.stringMin')}
                ]}>
                <Input autoComplete='email' maxLength={100} />
            </Form.Item>
            <Form.Item>
                <Space>
                    <Button type='primary' htmlType='submit' onClick={() => setSubscribe(true)}>
                        {t('newsletter.subscribe')}
                    </Button>
                    <Button type='default' htmlType='submit' onClick={() => setSubscribe(false)}>
                        {t('newsletter.unsubscribe')}
                    </Button>           
                </Space>
            </Form.Item>
        </Form>
        {showResult()}
    </div>
  );
}

export default Newsletter;
